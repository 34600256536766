import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { MigrationFilesProvider } from '.';
import i18n from '../i18n/config';
import { CurrentUser } from '../models';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});
export const GlobalProvider: React.FC<{ currentUser?: CurrentUser }> = ({ children }) => {
  return (
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <MigrationFilesProvider>{children}</MigrationFilesProvider>
      </QueryClientProvider>
    </I18nextProvider>
  );
};
