import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { mockCurrentUser } from '../__mocks__/mockCurrentUser';
import { environment } from '../environments/environment';
import { HttpStatusCode } from '../models/errors';
import mockData from './mockData.json';

if (environment.environmentName === 'development') {
  const mock = new MockAdapter(axios, { delayResponse: 500 });

  mock
    .onGet(
      `https://api.staging.beta.qualio.com/v2/${mockCurrentUser.companyId}/users?company_id=${mockCurrentUser.companyId}`,
    )
    .reply(HttpStatusCode.OK, mockData.user);

  mock
    .onGet(`https://api.staging.beta.qualio.com/document_templates?company_id=${mockCurrentUser.companyId}`)
    .reply(HttpStatusCode.OK, mockData.template);

  mockData.template.forEach((template: any) => {
    mock
      .onGet(
        `https://api.staging.beta.qualio.com/v2/${mockCurrentUser.companyId}/template/${template.id}/next_document_code`,
      )
      .reply(HttpStatusCode.OK, `${template.document_id_prefix}${template.id}`);
  });

  mock
    .onGet(`https://api.staging.beta.qualio.com/tags?company_id=${mockCurrentUser.companyId}`)
    .reply(HttpStatusCode.OK, mockData.tags);

  // Let everything else through
  mock.onAny().passThrough();
}

export const apiClient = axios.create({
  baseURL: environment.API_URL,
  withCredentials: true,
});
