export const environment = {
  production: true,
  PUBLIC_API: 'https://api.app.qualio.com',
  API_URL: 'https://api.migrations.app.qualio.com',
  APP_URL: 'https://app.migrations-manager.qualio.com',
  environmentName: 'production',
  loginUrl: 'https://app.qualio.com/login',
  authSuccessRefreshIntervalMillis: 60000,
  authErrorRetryIntervalMillis: 5000,
  translationsUrl: '/translations/{{lng}}/{{ns}}.json',
  LOG_LEVEL: 'debug',
  ldClientSideId: '61e6a31060139c1610d1516c',
};
